import React, { useState } from 'react';
import {
  FieldError,
  UseFormMethods,
} from 'react-hook-form';
import MuiTextField from '@material-ui/core/TextField';

import { InputErrorMessage } from '../InputErrorMessage';

interface InputProps {
  maxLength: number;
}

export interface TextFieldProps extends Pick<UseFormMethods, 'register'> {
  autocomplete?: string;
  dataAutocomplete?: string;
  disabled?: boolean;
  name: string;
  type: 'text' | 'password' | 'email' | 'number' | 'search' | 'tel' | 'url' | 'time' | 'week' | 'month' | 'date' | 'datetime-local';
  placeholder?: string;
  error?: FieldError;
  fullWidth?: boolean;
  multiline?: boolean;
  defaultValue?: string | number;
  rows?: number;
  inputProps?: InputProps;
  id?: string;
  variant?: 'filled' | 'outlined' | 'standard';
  size?: 'medium' | 'small' | undefined;
}

export const TextField = ({
  autocomplete,
  dataAutocomplete,
  disabled = false,
  defaultValue,
  error,
  fullWidth = true,
  multiline,
  id,
  inputProps,
  name,
  placeholder,
  register,
  rows,
  type,
  variant = 'standard',
  size = 'small',
}: TextFieldProps) => {
  const [value, setValue] = useState(defaultValue || '');

  return (
    <div style={fullWidth ? { width: '100%' } : {}}>
      <MuiTextField
        autoComplete={autocomplete}
        data-autocomplete={dataAutocomplete}
        error={!!error}
        name={name}
        disabled={disabled}
        type={type}
        label={placeholder}
        id={id}
        helperText={typeof value === 'string' ? inputProps?.maxLength && `${value.length}/${inputProps?.maxLength}` : null}
        onChange={e => setValue(e.target.value)}
        inputProps={inputProps}
        inputRef={register}
        multiline={multiline}
        rows={rows}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        variant={variant}
        size={size}
      />
      {error?.message && <InputErrorMessage message={error.message} />}
    </div>
  );
};
