import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  fieldErrorMessage: {
    color: palette.error.main,
    fontSize: '0.75rem',
    lineHeight: '14px',
    padding: '8px 14px 0',
  },
}));
