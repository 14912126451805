import { snakeCase } from 'change-case';

import { useStyles } from './InputErrorMessage.styles';

interface InputErrorMessageProps {
  message: string;
}

export const InputErrorMessage = ({ message }: InputErrorMessageProps) => {
  const classes = useStyles();

  const messageFormatted = snakeCase(message).replace(/_/g, ' ');

  return (
    <div className={classes.fieldErrorMessage}>
      {`${messageFormatted[0].toUpperCase()}${messageFormatted.substring(1)}`}
    </div>
  );
};
